.wrapper {
  padding: 32px 24px;
  display: flex;
  justify-content: center;
}

.content {
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 620px;
}
