.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
}

.img {
  margin-bottom: 24px;
  max-width: 656px;
}

.desc {
  max-width: 656px;
  text-align: center;
  margin-bottom: 24px;
}

/* Mobile-specific styles */
@media (max-width: 767px) {
  .wrapper {
    padding: 16px;
  }
}
