.contentWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.formWrapper {
  width: 100%;
  max-width: 656px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.formInnerWrapper {
  width: 100%;
  max-width: 530px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 48px;
}

.title {
  margin-bottom: 24px !important;
}

/* Mobile-specific styles */
@media (max-width: 767px) {
  .contentWrapper {
    padding: 0 24px;
  }
  .title {
    margin-bottom: 12px !important;
  }

  .formInnerWrapper {
    padding-top: 32px;
  }
}
