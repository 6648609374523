.sider {
  border-right: 1px solid rgba(5, 5, 5, 0.06);
  padding: 8px;
}

.header {
  border-bottom: 1px solid rgba(5, 5, 5, 0.06);
  background: rgb(255, 255, 255);
}

.menu {
  height: calc(100% - 82px);
  border-inline-end: unset !important;
}

.content {
  background: rgb(255, 255, 255);
  overflow-y: auto;
}

.logoWrapper {
  padding: 15px 0px 16px 26px;
}

.navigation {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: calc(100% - 60px);
}

.mobileMenu {
  display: flex;
  flex-direction: column;
  height: calc(100% - 32px);
  align-items: center;
  justify-content: space-between;
}

/* Mobile-specific styles */
@media (max-width: 767px) {
  .header {
    padding: 0 24px;
  }
}
