.body {
  padding: 32px 114px;
  background: #f5f5f5;
}

.active {
  background:
    linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.7) 0%,
      rgba(255, 255, 255, 0.7) 100%
    ),
    url('/public/images/layer.svg') lightgray 50% / cover no-repeat;
}

.card {
  width: 100%;
  height: 134px;
  display: flex;
  padding: 12px;
  flex-direction: column;
  gap: 24px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(4px);
}

.custom-dropdown-menu-item:hover {
  background-color: transparent !important;
  cursor: unset !important;
}

.emptyContainer {
  min-height: 308px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.cardWrapper {
  display: flex;
  gap: 12px;
  width: 100%;
  margin-bottom: 12px;
}

/* Mobile-specific styles */
@media (max-width: 767px) {
  .body {
    padding: 10px 12px;
  }

  .cardWrapper {
    flex-direction: column;
  }
}
