.wrapper {
  display: flex;
  width: 100%;
  height: 100vh;
}

.left {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.leftInner {
  width: 100%;
  max-width: 440px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}

.right {
  width: 50%;
  background-image: url('/public/images/sign-in-page.svg');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

/* Mobile-specific styles */
@media (max-width: 767px) {
  .left {
    width: 100%;
    padding: 0 24px;
  }
  .right {
    width: 0%;
    display: none;
  }
}
