.body {
  padding: 32px 114px;
}

.detailsCardWrapper {
  display: flex;
  gap: 12px;
  width: 100%;
}

.detailsCard {
  border-radius: 4px;
  background: #f5f5f5;
  padding: 12px;
  width: 100%;
}

/* Mobile-specific styles */
@media (max-width: 767px) {
  .body {
    padding: 10px 12px;
  }

  .detailsCardWrapper {
    flex-direction: column;
  }
}
