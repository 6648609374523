.content {
  max-height: 500px;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.rioMessage {
  height: max-content;
  padding: 12px 16px;
  gap: 16px;
  border-radius: 24px 24px 0px 24px;
  background: #eaebf0;
  overflow-wrap: break-word;
  white-space: wrap;
}

.userMessage {
  height: max-content;
  padding: 12px 16px;
  gap: 16px;
  border-radius: 24px 24px 24px 0px;
  background: #f5f5f5;
  overflow-wrap: break-word;
  white-space: wrap;
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 20px;
}

.header {
  display: flex;
  align-items: center;
  gap: 16px;
}
