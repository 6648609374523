.wrapper {
  padding: 16px 32px 24px 24px;
}

.card {
  width: max-content;
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #efeffe;
}

.cardScore {
  color: rgba(0, 0, 0, 0.88);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.cardInfo {
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
