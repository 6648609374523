.body {
  padding: 32px 114px;
}

.cardWrapper {
  display: flex;
  gap: 12px;
  width: 100%;
}

.card {
  border-radius: 4px;
  background: #f5f5f5;
  padding: 12px;
  width: 100%;
  min-height: 140px;
}

/* Mobile-specific styles */
@media (max-width: 767px) {
  .body {
    padding: 10px 12px;
  }
  .cardWrapper {
    flex-direction: column;
  }
}
