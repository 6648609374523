.header {
  display: flex;
  gap: 20px;
  padding: 32px;
  width: 100%;
  height: 100%;
  max-height: 280px;
  background: #f5f5f5;
  border-bottom: 1px solid #d9d9d9;
}

.productManagerCard {
  width: 40%;
  border-radius: 8px;
  border: 1px solid #f0f0f0;
  background: rgba(255, 255, 255, 0.65);
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 16px;
}

.intelligenceCard {
  width: 60%;
  border-radius: 8px;
  border: 1px solid #f0f0f0;
  background: #fff;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 20px 16px 16px 16px;
}

.tag {
  padding: 9px 12px;
  margin-bottom: 8px;
  font-size: 16px;
}

.card {
  display: flex;
  width: 100%;
  padding: 16px;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  background: #f5f5f5;
}

.hmCardWrapper {
  padding: 32px 114px;
}

/* Mobile-specific styles */
@media (max-width: 767px) {
  .header {
    flex-direction: column;
    max-height: unset;
  }
  .productManagerCard {
    width: 100%;
  }

  .intelligenceCard {
    width: 100%;
  }

  .hmCardWrapper {
    padding: 10px 12px;
  }
}
