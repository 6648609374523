.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  border-bottom: 1px solid rgba(5, 5, 5, 0.06);
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 4;
}
