.wrapper {
  padding: 16px 32px 24px 24px;
}

.nowrap {
  text-wrap: nowrap;
}

/* Mobile-specific styles */
@media (max-width: 767px) {
  .wrapper {
    padding: 16px;
  }
}
