.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  border-bottom: 1px solid rgba(5, 5, 5, 0.06);
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 4;
}

.wrapper {
  padding: 16px 32px 24px 24px;
}

.card {
  width: 100%;
  display: flex;
  padding: 24px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  border-radius: 8px;
  background: #efeffe;
  margin-bottom: 32px;
}

.row {
  display: flex;
  align-items: center;
}

.content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form {
  width: 100%;
  max-width: 620px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
