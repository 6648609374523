.infoWrapper {
  padding: 24px;
  background: #f5f5f5;
}

.infoCard {
  display: flex;
  width: 100%;
  padding: 24px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  background: #fff;
}

.wrapper {
  background: #f5f5f5;
}

.content {
  padding: 32px 114px;
  background-color: #fff;
}

.timelineWrapper {
  width: 100%;
  display: flex;
  gap: 200px;
}
.dateWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nameWrapper {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;
}

/* Mobile-specific styles */
@media (max-width: 767px) {
  .infoWrapper {
    padding: 0 0 24px 0;
  }
  .timelineWrapper {
    flex-direction: column;
    gap: 20px;
  }
  .dateWrapper {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
  .content {
    padding: 10px 12px;
  }
  .nameWrapper {
    flex-direction: column;
    align-items: start;
  }
}
