.tag {
  padding: 9px 12px;
  margin-bottom: 8px;
  font-size: 16px;
}

.input {
  flex: 1;
  margin-bottom: 8px;
  min-width: 200px;
}

.addTag {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  flex: 1;
  background: #ffffff;
  border-color: #d9d9d9;
  color: rgba(0, 0, 0, 0.88);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
  border-style: dashed;
  font-size: 16px;
  line-height: 1.5;
  cursor: pointer;
  margin-bottom: 8px;
  margin-right: 0;
}

.addTagDisabled {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  flex: 1;
  background: #ffffff;
  border-color: #d9d9d9;
  color: rgba(0, 0, 0, 0.88);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
  border-style: dashed;
  font-size: 16px;
  line-height: 1.5;
  cursor: pointer;
  margin-bottom: 8px;
  margin-right: 0;
  pointer-events: none;
  opacity: 0.6;
}

.wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}
