html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.ant-table-thead > tr > th {
  white-space: nowrap;
}
